import React, { Component, useState } from 'react'; 
import Input from "./components/Input";
import TextArea from "./components/TextArea";
import Button from "./components/Button";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './stule.css';


function FormContainer () {

    const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: '',
      domen: 'Асан',
    });
    const [responseMessage, setResponseMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      setResponseMessage(''); // Clear previous messages

      try {
        const response = await fetch('https://client.asan-pro.ru/send-message', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          setResponseMessage('Сообщение успешно отправлено!');
          setFormData({ name: '', email: '', message: '', domen: 'Асан' }); // Clear form fields
        } else {
          const errorData = await response.json();
          setResponseMessage(`Ошибка: ${errorData.message || 'Не удалось отправить сообщение.'}`);
        }
      } catch (error) {
        setResponseMessage(`Ошибка: ${error.message}`);
      } finally {
        setIsSubmitting(false);
      }
    };

 
  
        return (
          <div className="contact-form-container">
            <form className="contact-form" onSubmit={handleSubmit}>
            <label htmlFor="name">Ваше имя:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />

            <label htmlFor="email">Ваш email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />

            <label htmlFor="message">Сообщение:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              required
            />

            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Отправка...' : 'Отправить'}
            </button>
          </form>

          {responseMessage && <p className="response-message">{responseMessage}</p>}
        </div>
        );
};

export default FormContainer;
