/* eslint-disable jsx-a11y/anchor-is-valid */

import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Adress from "../../Sidebars/Adres";
import { useContext } from 'react';
import { MyContext } from '../../MyContext';
import Pagination from "./Pagination";
import PhotoGalleryPreview from "../../Sidebars/PhotoGalleryPreview/PhotoGalleryPreview";





function FullArticles(){
  
    const name = useContext(MyContext);

    useEffect(() => {
        document.title = "АсАн - Полезные статьи мастерской АсАн"
    }, []);

    const [articles, setArticle] = useState(name);

    const result = articles.map((articl) => {
        return (
            <><div className="item row">
                <a className="col-md-4 col-sm-4 col-xs-12" key={articl.id}>
                    <img
                        class="img-responsive project-image"
                        src={articl.src}
                        alt={articl.alt} />
                </a>
                <div className="desc col-md-8 col-sm-8 col-xs-12">
                    <h3 class="title">{articl.title}</h3>
                   <p class="summary">{articl.preview}</p>
                    <br />
                    <Link className="more-link" to={articl.url}><i className="fa fa-external-link"></i>Читать полностью</Link>
                </div>
            </div><hr /></> 
        );
    });

    return (
        <div>
            <body>
                <Header />
                <div class="container sections-wrapper">
                    <div class="row">
                        <div class="primary col-md-8 col-sm-12 col-xs-12">
                            <section class="latest section">
                                <div class="section-inner">
                                    <h2 class="heading">Полезные статьи мастерской АсАн</h2>
                                    
                                 
                                    
                                    <Pagination name={result} />
<br />
                                    <Link class="btn btn-cta-secondary" to={`/`}>
                                        Вернуться на главную
                                    </Link>
                                </div>
                            </section>
                        </div>
                        <div class="secondary col-md-4 col-sm-12 col-xs-12">
                            <Adress />
                           <PhotoGalleryPreview />
                    
                        </div>
                    </div>
                </div>

                <Footer />
            </body>
        </div>
 );
}
export default FullArticles;
