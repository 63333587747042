import React, { useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";


function Pagination(props) {
  const itemsPerPage = 3; // Number of items per page
  const totalItems = props.name.length; // Total number of items
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const [PositionOne, setPositionOne] = useState(0);
  const PositionOneChanges = (newPage) => {
    setPositionOne(newPage);
  };

  const [PositionTwo, setPositionTwo] = useState(3);
  const PositionTwoChanges = (newPage) => {
    setPositionTwo(newPage);
  };

  function mimus() {
    handlePageChange(currentPage - 1);
    PositionOneChanges(PositionOne - itemsPerPage);
    PositionTwoChanges(PositionTwo - itemsPerPage);
  }
  function plus() {
    handlePageChange(currentPage + 1);
    PositionOneChanges(PositionOne + itemsPerPage);
    PositionTwoChanges(PositionTwo + itemsPerPage);
  }

  

  return (
    <>
      <div class="content">{props.name.slice(PositionOne, PositionTwo)}</div>
      <div className="pagination">
        <Link
          className="pagination__Link"
          style={{ pointerEvents: currentPage === 1 ? 'none' : '' }} to={`/FullArticles/`}
          onClick={() => mimus()}
        >
          Назад
        </Link>
        <span className="pagination__Link"> {currentPage}</span>
        <Link
          className="pagination__Link"
          style={{ pointerEvents: currentPage === totalPages ? 'none' : '' }} to={`Articles/` + currentPage}
          onClick={() => plus()}
        >
          Вперёд
        </Link>
     
      </div>
    </>
  );
}

export default Pagination;
